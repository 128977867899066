import React from "react";
import * as classes from "./Services.module.scss";
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
const { Services, Image, Wrapper } = classes;

export default () => {
  const { houseIcon, lightBulbIcon, plantIcon } = useStaticQuery(graphql`
    {
      houseIcon: file(name: { eq: "house-badge" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lightBulbIcon: file(name: { eq: "responsible-energy-use" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      plantIcon: file(name: { eq: "hand-holding-up-a-sprout" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const services = [
    {
      title: "Wohlbefinden und Gesundheit",
      url: "/gesund-bauen/Wohlbefinden-und-Gesundheit-index",
      fluid: houseIcon.childImageSharp.fluid,
    },
    {
      title: "Energieeffizient Bauen",
      url: "/gesund-bauen/energieeffizient-index",
      fluid: lightBulbIcon.childImageSharp.fluid,
    },
    {
      title: "Nachhaltig Bauen",
      url: "/gesund-bauen/nachhaltig-index",
      fluid: plantIcon.childImageSharp.fluid,
    },
  ];

  return (
    <div className={Services}>
      {services.map(service => (
        <section key={service.fluid.src}>
          <Link to={service.url}>
            <div className={Image}>
              <div className={Wrapper}>
                <Img fluid={service.fluid} />
              </div>
            </div>
            <h3>{service.title}</h3>
          </Link>
        </section>
      ))}
    </div>
  );
};
