import React from "react";
import * as classes from "./HeroPopup.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
const { HeroPopup } = classes;
export default () => {
  const { popupLogo } = useStaticQuery(graphql`
    {
      popupLogo: file(name: { eq: "TDA_2023_RGB_small" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={HeroPopup}>
      <h5>Besuchen Sie uns zum</h5>
      <a href="https://naturbauschule.de/Veranstaltungen-in-Berlin-c145424891"><Img
        fluid={popupLogo.childImageSharp.fluid}
        alt="Tag der Architektur 2023"
      /></a>
      <h5>24. Juni 2023, 14-18 Uhr</h5>
    </div>
  );
};
